import React, { Component } from "react"
import PropType from "prop-types"
import { SeeMore } from "../styles/common"
const dateFormat = date => {
  let arrayDate = date.split("-")
  return {
    day: arrayDate[2].substring(0, 2),
    month: month[arrayDate[1]],
    year: arrayDate[0],
  }
}

const month = {
  "01": "Jan",
  "02": "Feb",
  "03": "Mar",
  "04": "Apr",
  "05": "May",
  "06": "Jun",
  "07": "Jul",
  "08": "Aug",
  "09": "Sep",
  "10": "Oct",
  "11": "Nov",
  "12": "Dec",
}

class ReadMore extends Component {
  constructor(props) {
    super(props)
    this.state = {
      maxLength: 255,
      expanded: false,
    }
  }

  toggleExpanded = () =>
    this.setState(prevState => ({
      expanded: !prevState.expanded,
    }))

  render() {
    const { text, maxLength } = this.props
    const { expanded } = this.state
    if (text.length < maxLength) {
      return <p>{text}</p>
    } else
      return (
        <p>
          {expanded ? text : `${text.substring(0, maxLength)}...`}
          <SeeMore onClick={this.toggleExpanded}>
            {!expanded ? "Read more" : "show less"}
          </SeeMore>
        </p>
      )
  }
}

ReadMore.propTypes = {
  text: PropType.string.isRequired,
  maxLength: PropType.number,
}

ReadMore.defaultProps = {
  maxLength: 140,
}

const validate = email => {
  // const expression = /(?!.*\.{2})^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([ \t]*\r\n)?[ \t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([ \t]*\r\n)?[ \t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i
  const reg = /\S+@\S+\.\S+/
  return reg.test(String(email).toLowerCase())
}

export { dateFormat, ReadMore, validate }
