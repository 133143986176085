import React from "react"
import { LoaderContainer } from "../styles/common"

const Loader = ({ absolute = false }) => (
  <LoaderContainer absolute={absolute}>
    <div />
    <div />
  </LoaderContainer>
)

export default Loader
