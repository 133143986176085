import styled from "styled-components"
import casablanca from "../images/casablanca.jpg"
import quebec from "../images/quebec2.jpg"
import valencia from "../images/barcelona3.jpg"
import agadir from "../images/agadir.jpg"

export const Offices = styled.ul`
  display: flex;
  margin: 80px 0 0;
  padding: 0;
  list-style: none;
  width: 100%;
  align-items: stretch;
  justify-content: center;
  flex-wrap: wrap;
  h3 {
    font-family: Lato;
    text-transform: uppercase;
    font-size: 30px;
    color: white;
    position: relative;
    padding-bottom: 20px;
    font-weight: 900;
    transition: 300ms;
    &:after {
      height: 3px;
      content: "";
      position: absolute;
      bottom: 0;
      width: 50px;
      background-color: #a45fa7;
      left: calc(50% - 25px);
    }
  }

  p {
    font-size: 18px;
    line-height: 24px;
    color: white;
    margin-bottom: 0;
    opacity: 0;
  }
`
export const OfficeItem = styled.li`
  padding: 30px 30px 10px;
  width: 25%;
  position: relative;
  text-align: center;
  background: no-repeat center / cover;
  box-sizing: border-box;
  &:nth-child(1) {
    background-image: url(${casablanca});
  }

  &:nth-child(2) {
    background-image: url(${agadir});
  }

  &:nth-child(3) {
    background-image: url(${quebec});
  }

  &:nth-child(4) {
    background-image: url(${valencia});
  }

  &:after {
    content: " ";
    position: absolute;
    background-color: #000;
    opacity: 0.3;
    left: 0;
    top: 0;
    z-index: 0;
    bottom: 0;
    transition: 300ms;
    right: 0;
  }

  &:hover p {
    opacity: 1;
  }

  &:hover h3 {
    font-size: 20px;

    &:after {
      background-color: white;
    }
  }

  &:hover:after {
    background-color: rgb(0, 0, 0);
    opacity: 0.7;
  }

  ${({ theme }) => theme.md`
        width: 50%;
        background-size: auto;
        &:first-child {
            width: 100%;
        }
    `}

  ${({ theme }) => theme.sm`
        width: 100%;
        padding-top: 30px;
        padding-bottom: 30px;
    `}
`

export const OfficeInner = styled.div`
  position: relative;
  z-index: 1;
`

export const OfficeIconholder = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid white;
  border-radius: 50%;
  // width: 90px;
  // height: 90px;
  padding: 8px;
  display: inline-block;
  margin: 0 auto;
  transition: 300ms;
  span {
    font-size: ${props => props.size};
    transition: 300ms;
    color: white;
  }

  ${OfficeItem}:hover & {
    // width: 65px;
    // height: 65px;
    span {
      font-size: calc(${props => props.size} - 17px);
    }
  }
`

export const ContactInfo = styled.div`
  width: 40%;
  padding-right: 5%;
  padding-left: 10%;
  box-sizing: border-box;
  & h4 {
    color: #a45fa7;
    font-size: 20px;
    margin-bottom: 20px;
  }

  ul {
    padding: 0;
    margin: 0;
  }
  & li {
    list-style: none;
    margin-bottom: 10px;
  }

  & li a {
    font-size: 16px;
    color: #333;
    text-decoration: none;
  }

  & li [class^="icon-"] {
    color: #a45fa7;
  }

  ${({ theme }) => theme.md`
        padding-left: 0;
        width: 40%;
    `}

  ${({ theme }) => theme.sm`
    width: 100%;
    padding: 0 0 20px;

    h4 {
        margin-top: 0;
    }
`}
`

export const ContactForm = styled.form`
  width: 60%;
  padding-right: 10%;
  box-sizing: border-box;
  ${({ theme }) => theme.md`
        padding-right: 0;
    `}

  ${({ theme }) => theme.sm`
    width: 100%;
`}
`
